.Bubble {
  height: auto;
  width: auto;
  max-width: 16.6rem;
  border-radius: 15px;
  padding: 8px 15px;
  margin-top: 0px;
  margin-bottom: 2px;
  display: flex;
  text-align: start;
}

.Hidden {
  display: none;
}

@media (min-width:600px) {
  .Bubble {
    max-width: 60rem;
  }
}

.Gray {
  align-items: flex-start;
  color: black;
}

.Gray .Bubble {
  background-color: #e5e5e5;
  position: relative;
}

.Gray .Bubble.PointerTail:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -7px;
  height: 20px;
  width: 20px;
  background: #e5e5e5;
  border-bottom-right-radius: 15px;
  -webkit-animation: PointerTail 0.5s;
  -moz-animation: PointerTail 0.5s;
  animation: PointerTail 0.5s;
}

.Gray .Bubble.PointerTail:after {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-right-radius: 10px;
}

.Gray .Bubble.TrailTail:before {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: -2px;
  left: -4px;
  height: 14px;
  width: 14px;
  background: #e5e5e5;
  border-radius: 50%;
  -webkit-animation: pulsateSecondBubble 1.5s infinite alternate;
  -moz-animation: pulsateSecondBubble 1.5s infinite alternate;
  animation: pulsateSecondBubble 1.5s infinite alternate;
}

.Gray .Bubble.TrailTail:after {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: -7px;
  left: -12px;
  height: 7px;
  width: 7px;
  background: #e5e5e5;
  border-radius: 50%;
  -webkit-animation: pulsateFirstBubble 1s infinite alternate;
  -moz-animation: pulsateFirstBubble 1s infinite alternate;
  animation: pulsateFirstBubble 1s infinite alternate;
}

.Blue {
  align-items: flex-end;
  color: white;
}

.Blue .Bubble {
  color: white;
  background: #0085D1 100%;
  background-attachment: fixed;
  position: relative;
}

.Blue .Bubble.PointerTail:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background: #0085D1;
  background-attachment: fixed;
  border-bottom-left-radius: 15px;
  -webkit-animation: PointerTail 0.5s;
  -moz-animation: PointerTail 0.5s;
  animation: PointerTail 0.5s;
}

.Blue .Bubble.PointerTail:after {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-left-radius: 10px;
}

.Blue .Bubble.TrailTail:before {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: -2px;
  right: -4px;
  height: 14px;
  width: 14px;
  background: #0085D1;
  background-attachment: fixed;
  border-radius: 50%;
  -webkit-animation: pulsateSecondBubble 1.5s infinite alternate;
  -moz-animation: pulsateSecondBubble 1.5s infinite alternate;
  animation: pulsateSecondBubble 1.5s infinite alternate;
}

.Blue .Bubble.TrailTail:after {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: -7px;
  right: -12px;
  height: 7px;
  width: 7px;
  background: #0085D1;
  background-attachment: fixed;
  border-radius: 50%;
  -webkit-animation: pulsateFirstBubble 1s infinite alternate;
  -moz-animation: pulsateFirstBubble 1s infinite alternate;
  animation: pulsateFirstBubble 1s infinite alternate;
}

@keyframes pulsateFirstBubble {
  0% {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
  }
  100% {
    transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -moz-transform: scale(1.3);
  }
}

@keyframes pulsateSecondBubble {
  0% {
    transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -moz-transform: scale(0.95);
  }
  100% {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
  }
}

@keyframes PointerTail {
  0% {
    opacity: 0.6;
    transform: scale(0.2);
    -webkit-transform: scale(0.2);
    -moz-transform: scale(0.2);
  }
  100% {
    opacity: 1;
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
    -moz-transform: scale(1.0);
  }
}