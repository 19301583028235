.BubbleContainer {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
}

.BubbleContainer.StartAlign{
    align-items: flex-start
}

.BubbleContainer.EndAlign{
    align-items: flex-end
}