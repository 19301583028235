:root {
  --loading-color: #505050cc;
}

.Blue {
  --loading-color: #505050cc
}

.Gray {
  --loading-color: #969696
}

.Loading {
  display: flex;
  z-index: 0;
  width: auto;
  height: 1.5rem;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.Loading .Dot {
  height: 0.8rem;
  width: 0.8rem;
  z-index: 1;
  border-radius: 50%;
  background: var(--loading-color);
  margin: 0 0 0 0.4rem;
}

.Loading .Dot:first-child {
  margin: 0rem;
}

.Loading .Dot.One {
  animation: CycleOne 1s ease-in-out infinite;
  -moz-animation: CycleOne 1s ease-in-out infinite;
  -webkit-animation: CycleOne 1s ease-in-out infinite;
  animation-direction: normal;
  -moz-animation-direction: normal;
  -webkit-animation-direction: normal;
}

.Loading .Dot.Two {
  animation: CycleTwo 1s ease-in-out infinite;
  -moz-animation: CycleTwo 1s ease-in-out infinite;
  -webkit-animation: CycleTwo 1s ease-in-out infinite;
  animation-direction: normal;
  -moz-animation-direction: normal;
  -webkit-animation-direction: normal;
}

.Loading .Dot.Three {
  animation: CycleThree 1s ease-in-out infinite;
  -moz-animation: CycleThree 1s ease-in-out infinite;
  -webkit-animation: CycleThree 1s ease-in-out infinite;
  animation-direction: normal;
  -moz-webkit-animation-direction: normal;
  -webkit-animation-direction: normal;
}

@keyframes CycleOne {
  0% {
    background: rgba(150, 150, 150, 0.4);
  }
  33.333% {
    background: var(--loading-color);
  }
  66.6667% {
    background: rgba(150, 150, 150, 0.4);
  }
  100% {
    background: rgba(150, 150, 150, 0.4);
  }
}

@keyframes CycleTwo {
  0% {
    background: rgba(150, 150, 150, 0.4);
  }
  33.333% {
    background: rgba(150, 150, 150, 0.4);
  }
  66.6667% {
    background: var(--loading-color);
    ;
  }
  100% {
    background: rgba(150, 150, 150, 0.4);
  }
}

@keyframes CycleThree {
  0% {
    background: rgba(150, 150, 150, 0.4);
  }
  33.333% {
    background: rgba(150, 150, 150, 0.4);
  }
  66.6667% {
    background: rgba(150, 150, 150, 0.4);
  }
  100% {
    background: var(--loading-color);
    ;
  }
}