.ContainerTraditionalResume {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}

.ContainerTraditionalResume .DarkTheme {
  background-color: #212529;
  color: #7c868f;
  padding-left: 10px;
}

.ContainerTraditionalResume .LightTheme {
  background-color: rgb(245, 245, 245);
  color: #212529;
  padding-left: 10px;
}

.DarkTheme a {
  color: #7c868f
}

.LightTheme a {
  color: #212529
}

.DarkTheme li {
  margin: 3px 0;
}

.LightTheme li {
  margin: 3px 0;
}

.DarkTheme b {
  font-weight: 600;
}

.LightTheme b {
  font-weight: 600;
}